<template>
  <main>
    <div v-if="currentUser" class="max-w-7xl mx-auto sm:px-6 lg:px-8 py-9">

      <div class="grid grid-cols-4 gap-4">
        <div class="col-span-1">
          <litepie-datepicker
            ref="litePieDatePicker"
            :auto-apply="false"
            :formatter="formatter"
            v-model="dateRange"
          />
        </div>
        <div class="col-span-3">
          <div v-if="currentUser.user_metadata.timezone" class="text-gray-400 mt-2 text-sm">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
            </svg>
            {{currentTimezone}}
          </div>
          <div v-else class="text-yellow-500 mt-2 text-sm">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
              No timezone set in your profile settings. Using {{currentTimezone}}.
          </div>
        </div>
      </div>

      <template v-if="loadingData">
        <Loading />
      </template>
      <template v-else>

        <div v-if="reportHabitData.length" class="pt-5">
          <template v-for="report in reportHabitData" v-bind:key="report">
          <div
                  class="mt-2 mb-2 shadow-xl flex flex-col text-center bg-white rounded-t-md rounded-b-none shadow overflow-hidden relative border-4 h-auto"
                  :class="'bg-' + report.color + '-100 border-' + report.color + '-900'"
          >
            <div
                    @click="report.selected = !report.selected"
                    class="z-20 p-4 relative shadow-none flex items-center cursor-pointer"
                    :class="'bg-transparent border-' + report.color + '-700'"
            >
              <div class="flex-shrink-0">
                <svg v-if="report.selected" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
              </div>
              <div class="flex-shrink-0">
                <span class="rounded-lg inline-flex p-2"
                      :class="'bg-' + report.color + '-50'">
                  <BlockIcon
                          v-bind:type="report.faceType"
                          v-bind:color="report.color"
                  ></BlockIcon>
                </span>
              </div>
              <div class="flex w-20 ml-2 text-left">
                {{report.title}}
              </div>

              <div v-for="reportData in report.data" v-bind:key="reportData" class="flex-auto">
                <!-- Completed Step -->
                <template v-if="reportData.size <= reportData.total && reportData.size !== 0">
                  <a href="#" class="relative w-8 h-8 flex items-center justify-center rounded-full"
                     :class="'bg-' +  report.color + '-500 hover:bg-' + report.color + '-900'"
                  >
                    <!-- Heroicon name: solid/check -->
                    <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                  </a>
                </template>
                <template v-else-if="reportData.total > 0 && reportData.size !== 0">
                  <a href="#" :class="'border-' +  report.color + '-500'" class="relative w-8 h-8 flex items-center justify-center bg-white border-2 rounded-full" aria-current="step">
                    <span :class="'bg-' +  report.color + '-500'" class="h-2.5 w-2.5 rounded-full" aria-hidden="true"></span>
                  </a>
                </template>
                <template v-else>
                  <a href="#" :class="'border-' +  report.color + '-500'" class="relative w-8 h-8 flex items-center justify-center bg-white border-2 rounded-full" aria-current="step">
                  </a>
                </template>
              </div>
            </div>
            <div v-if="report.selected" class="transition-all duration-700">
              <div>
                <apexchart type="area" height="200" :options="report.chartOptions" :series="report.series"></apexchart>
              </div>
            </div>
          </div>
        </template>
        </div>

        <div class="grid grid-cols-4 pt-5">
          <div id="totalChart" class="col-span-1">
            <h2 class="text-2xl">Total time</h2>
            <span class="text-xl">{{totalTime}}</span>
          </div>
          <div id="donutChart" class="col-span-1">
            <DonutChart
                    :values="reportsProjectsDonutChart.values"
                    :colors="reportsProjectsDonutChart.colors"
                    :labels="reportsProjectsDonutChart.labels"
            ></DonutChart>
          </div>

          <div id="donutChart3" class="col-span-1">
            <DonutChart
                    :values="reportsTitlesDonutChart.values"
                    :colors="reportsTitlesDonutChart.colors"
                    :labels="reportsTitlesDonutChart.labels"
            ></DonutChart>
          </div>

          <div id="donutChart2" class="col-span-1">
            <DonutChart
                    :values="reportsColorsDonutChart.values"
                    :colors="reportsColorsDonutChart.colors"
                    :labels="reportsColorsDonutChart.labels"
            ></DonutChart>
          </div>
        </div>

        <div id="chart">
          <BarChart :values="reportsProjectsBarChart" :categories="reportsDateRange"></BarChart>
        </div>

        <div id="chart3">
          <BarChart :values="reportsTitlesBarChart" :categories="reportsDateRange"></BarChart>
        </div>

        <div id="chart2">
          <BarChart :values="reportsColorsBarChart" :categories="reportsDateRange"></BarChart>
        </div>

        <div id="timeChart">
          <RangeBarChart :values="reportsProjectsRangeBarChart"></RangeBarChart>
        </div>

        <div id="timeChart3">
          <RangeBarChart :values="reportsTitlesRangeBarChart"></RangeBarChart>
        </div>

        <div id="timeChart2">
          <RangeBarChart :values="reportsColorsRangeBarChart"></RangeBarChart>
        </div>

      </template>
    </div>
    <div v-else class="py-6 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="rounded-md shadow-md bg-yellow-50 p-4 mb-5">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: solid/exclamation -->
            <svg class="border-2 border-yellow-200 h-12 w-12 text-yellow-400 bg-yellow-100 p-2 rounded" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3 flex-grow">
            <h3 class="text-md font-medium text-yellow-800">
              You are using free version of Kairo which does not store data.
            </h3>
            <div class="mt-1 text-sm text-yellow-700">
              <p>
                Sign up and subscribe to keep your data and access reports from anywhere.
              </p>
            </div>
          </div>
          <div class="flex-shrink">
            <router-link
                to="signup"
                class="mt-2 inline-flex items-center px-3 py-2 border-2 border-yellow-200 text-sm leading-4 font-medium rounded-md text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >Start trial
            </router-link>
          </div>
        </div>
      </div>

      <img class="block lg:block" src="@/assets/reports-sample.png"/>
    </div>
  </main>
</template>

<script>
  import {mapGetters} from "vuex";
  import {colors} from '../colors/colors.js';
  import { ref } from 'vue';
  import LitepieDatepicker from 'litepie-datepicker';
  import Loading from '../components/Loading';
  import {getReportsRange} from "../models/ReportsModel";
  import {getProjects} from "../models/ProjectsModel";
  import dayjs from 'dayjs';
  import utc from "dayjs/plugin/utc";
  import timezone from "dayjs/plugin/timezone";
  import {
    normalizeElements,
    reportsTotalTime,
    reportsPrepareDates,
    reportsBarChart,
    reportsRangeBarChart,
    reportsDonutChart
  } from "../reports/reports";
  import DonutChart from '../reports/components/DonutChart';
  import RangeBarChart from '../reports/components/RangeBarChart';
  import BarChart from '../reports/components/BarChart';
  import BlockIcon from "../components/BlockIcon";

  import VueApexCharts from "vue3-apexcharts";

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const habitChartOptions = {
    chart: {
      height: 200,
      type: 'area',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: [6, 3],
    },
    tooltip: {
      shared: false,
      intersect: true,
    },
    xaxis: {
      type: 'datetime',
    },
  };

  export default {
    components: {
      LitepieDatepicker,
      Loading,
      DonutChart,
      RangeBarChart,
      BarChart,
      BlockIcon,
      apexchart: VueApexCharts,
    },
    setup() {
      const myRef = ref(null);
      const dateRange = ref([]);
      const formatter = ref({
        date: 'DD MMM YYYY',
        month: 'MMM'
      });
      const reportsData = ref({});
      const loadingData = ref(true);
      const reportsDateRange = ref([]);
      const totalTime = ref(0);
      const reportsProjectsBarChart = ref([]);
      const reportsColorsBarChart = ref([]);
      const reportsTitlesBarChart = ref([]);
      const reportsProjectsRangeBarChart = ref([]);
      const reportsColorsRangeBarChart = ref([]);
      const reportsTitlesRangeBarChart = ref([]);
      const reportsProjectsDonutChart = ref([]);
      const reportsColorsDonutChart = ref([]);
      const reportsTitlesDonutChart = ref([]);

      const reportHabitData = ref({});

      const normalisedData = ref([]);

      const projects = ref([]);

      return {
        colors,
        myRef,
        dateRange,
        formatter,
        reportsData,
        loadingData,
        reportsDateRange,
        totalTime,
        reportsProjectsBarChart,
        reportsColorsBarChart,
        reportsTitlesBarChart,
        reportsProjectsRangeBarChart,
        reportsColorsRangeBarChart,
        reportsTitlesRangeBarChart,
        reportsProjectsDonutChart,
        reportsColorsDonutChart,
        reportsTitlesDonutChart,
        normalisedData,
        reportHabitData,
        projects
      };
    },
    computed: {
      ...mapGetters("auth", ["currentUser"]),
      currentTimezone() {
        if (this.currentUser.user_metadata && this.currentUser.user_metadata.timezone) {
          return this.currentUser.user_metadata.timezone;
        } else {
          return dayjs.tz.guess();
        }
      },
    },
    watch: {
      dateRange(newVal) {
        const from_start = dayjs(newVal[0]).tz(this.currentTimezone).startOf('day');
        const to_end = dayjs(newVal[1]).tz(this.currentTimezone).endOf('day');
        let djsf = from_start.format('DD MMM YYYY');
        let djte = to_end.format('DD MMM YYYY');

        this.dateRange.push(djsf);
        this.dateRange.push(djte);
        this.loadingData = true;
        getReportsRange(from_start.valueOf(), to_end.valueOf())
            .then(resp => {
              this.reportsData = resp.data;
              this.normalisedData = normalizeElements(resp.data, from_start, to_end, this.currentTimezone, this.projects);
              this.totalTime = reportsTotalTime(this.normalisedData.elementsBlock);
              this.reportsDateRange = reportsPrepareDates(from_start, to_end);
              this.reportsProjectsBarChart = reportsBarChart(this.normalisedData.elementsBlock, this.reportsDateRange, 'project');
              this.reportsColorsBarChart = reportsBarChart(this.normalisedData.elementsBlock, this.reportsDateRange, 'color');
              this.reportsTitlesBarChart = reportsBarChart(this.normalisedData.elementsBlock, this.reportsDateRange, 'title');
              this.reportsProjectsRangeBarChart = reportsRangeBarChart(this.normalisedData.elementsBlock, 'project');
              this.reportsColorsRangeBarChart = reportsRangeBarChart(this.normalisedData.elementsBlock, 'color');
              this.reportsTitlesRangeBarChart = reportsRangeBarChart(this.normalisedData.elementsBlock, 'title');
              this.reportsProjectsDonutChart = reportsDonutChart(this.normalisedData.elementsBlock, 'project');
              this.reportsColorsDonutChart = reportsDonutChart(this.normalisedData.elementsBlock, 'color');
              this.reportsTitlesDonutChart = reportsDonutChart(this.normalisedData.elementsBlock, 'title');

              this.habitSeries(this.reportsData, from_start.valueOf(), to_end.valueOf());

              this.loadingData = false;
            })
            .catch(err => console.error("problem getting reports", err));

      },
    },
    methods: {
      habitSeries(data, from_date, to_date) {
        let cal_start = from_date;
        let cal_end = to_date;
        let cal_djs_start = dayjs(cal_start);
        let cal_djs_end = dayjs(cal_end);
        let cal_diff = cal_djs_end.diff(cal_djs_start, 'day');

        // Prepare base structure for
        const reportData = [];
        const itemStructure = [];
        const emptyDataSeries = [];
        for (let i = 0; i < cal_diff + 1; i++) {
          let cal_this = cal_djs_start.add(dayjs.duration({'days' : i}));
          itemStructure.push({date: cal_this.format('YYYY-MM-DD'), elements: [], size: 0, total: 0});
          emptyDataSeries.push({x: cal_this.valueOf(), y: 0});
        }

        // Loop through the data
        data.forEach((report) => {
          // Loop through the Blocks
          report.data.blocks.forEach((block) => {
            if (block.type === 'BlockCounter') {
              let reportIndex = reportData.findIndex(item => item.title === block.title);
              // we didn't find it, let's create it
              if (reportIndex === -1) {
                let colorIndex = this.colors.findIndex(item => item.value === block.color);
                let chartOptions = JSON.parse(JSON.stringify(habitChartOptions));
                chartOptions.colors = [
                  '#' + this.colors[colorIndex].colors[900],
                  '#' + this.colors[colorIndex].colors[400],
                ];
                chartOptions.markers = {
                  size: [0,3],
                  colors: [
                    '#' + this.colors[colorIndex].colors[900],
                    '#' + this.colors[colorIndex].colors[600],
                  ],
                  strokeColors: [
                    '#' + this.colors[colorIndex].colors[900],
                    '#' + this.colors[colorIndex].colors[200],
                  ],
                  strokeWidth: [2, 0],
                };
                chartOptions.xaxis = {
                  type: 'datetime',
                };
                chartOptions.yaxis = {
                  min: 0,
                  max: 0
                };
                chartOptions.grid = {
                  borderColor: '#' + this.colors[colorIndex].colors[300],
                  strokeDashArray: 1,
                  xaxis: {
                    lines: {
                      show: true
                    }
                  },
                  yaxis: {
                    lines: {
                      show: true
                    }
                  },
                };
                chartOptions.annotations = {
                  yaxis: [
                    {
                      y: block.size,
                      borderColor: '#' + this.colors[colorIndex].colors[400],
                      label: {
                        borderColor: '#' + this.colors[colorIndex].colors[400],
                        style: {
                          color: '#fff',
                          background: '#' + this.colors[colorIndex].colors[400]
                        },
                        text: 'Goal'
                      }
                    }
                  ]
                };

                reportData.push({
                  title: block.title,
                  color: block.color,
                  size: block.size,
                  selected: false,
                  total: 0,
                  faceType: block.faceType,
                  data: JSON.parse(JSON.stringify(itemStructure)),
                  chartOptions: chartOptions,
                  series: [{
                    name: 'Points',
                    type: 'scatter',
                    data: []
                  },
                    {
                      name: 'Area',
                      type: 'area',
                      data: JSON.parse(JSON.stringify(emptyDataSeries))
                    }]
                });

                reportIndex = reportData.length - 1;
              }

              if (block.elements.length) {
                block.elements.forEach((element) => {
                  let dformat = dayjs(element.start).format('YYYY-MM-DD');
                  let index = reportData[reportIndex].data.findIndex(item => item.date === dformat);
                  // we found it - otherwise it is -1
                  if (index > -1) {
                    reportData[reportIndex].data[index].total = reportData[reportIndex].data[index].total + element.value;
                    if (reportData[reportIndex].total < reportData[reportIndex].data[index].total) {
                      reportData[reportIndex].chartOptions.yaxis.max = reportData[reportIndex].data[index].total;
                      reportData[reportIndex].total = reportData[reportIndex].data[index].total;
                    }
                    if (reportData[reportIndex].chartOptions.yaxis.max < block.size) {
                      reportData[reportIndex].chartOptions.yaxis.max = block.size;
                    }

                    reportData[reportIndex].data[index].size = block.size;
                    reportData[reportIndex].series[0].data.push({x: element.start, y: element.value});
                    reportData[reportIndex].series[1].data.push({x: element.start, y: reportData[reportIndex].data[index].total});

                    reportData[reportIndex].series[1].data.sort(function(a, b) {
                      if (a.x > b.x) {
                        return 1;
                      }
                      if (a.x < b.x) {
                        return -1;
                      }
                      return 0;
                    });
                  }
                });
              }
            }
          })
        });
        this.reportHabitData = reportData;
      },
    },
    mounted() {
      if (this.currentUser) {
        const from_start = dayjs().tz(this.currentTimezone).add(-8, 'day').startOf('day');
        const to_end = dayjs().tz(this.currentTimezone).add(-1, 'day').endOf('day');
        let djsf = from_start.format('DD MMM YYYY');
        let djte = to_end.format('DD MMM YYYY');
        this.dateRange.push(djsf);
        this.dateRange.push(djte);

        getProjects()
            .then(resp => {
              let projectsData = resp.data;

              projectsData.forEach((project) => {
                project.data.refID = project.ref.value.id;
                this.projects.push(project.data);
              });

              getReportsRange(from_start.valueOf(), to_end.valueOf())
                  .then(resp => {
                    this.reportsData = resp.data;
                    this.normalisedData = normalizeElements(resp.data, from_start, to_end, this.currentTimezone, this.projects);
                    this.totalTime = reportsTotalTime(this.normalisedData.elementsBlock);
                    this.reportsDateRange = reportsPrepareDates(from_start, to_end);
                    this.reportsProjectsBarChart = reportsBarChart(this.normalisedData.elementsBlock, this.reportsDateRange, 'project');
                    this.reportsColorsBarChart = reportsBarChart(this.normalisedData.elementsBlock, this.reportsDateRange, 'color');
                    this.reportsTitlesBarChart = reportsBarChart(this.normalisedData.elementsBlock, this.reportsDateRange, 'title');
                    this.reportsProjectsRangeBarChart = reportsRangeBarChart(this.normalisedData.elementsBlock, 'project');
                    this.reportsColorsRangeBarChart = reportsRangeBarChart(this.normalisedData.elementsBlock, 'color');
                    this.reportsTitlesRangeBarChart = reportsRangeBarChart(this.normalisedData.elementsBlock, 'title');
                    this.reportsProjectsDonutChart = reportsDonutChart(this.normalisedData.elementsBlock, 'project');
                    this.reportsColorsDonutChart = reportsDonutChart(this.normalisedData.elementsBlock, 'color');
                    this.reportsTitlesDonutChart = reportsDonutChart(this.normalisedData.elementsBlock, 'title');

                    this.habitSeries(this.reportsData, from_start.valueOf(), to_end.valueOf());

                    this.loadingData = false;
                  })
                  .catch(err => console.error("problem getting reports", err));

            })
            .catch(() => {
                //this.toast.error(`We are not able to fetch projects from the database.`);
              }
            );
      }
    },
  }

</script>
