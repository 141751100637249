<template>
  <apexchart type="bar" height="450"
    :options="barChart"
    :series="values"
    :categories="categories"
  ></apexchart>
</template>
<script>
  import VueApexCharts from "vue3-apexcharts";

  export default {
    name: 'BarChart',
    components: {
      apexchart: VueApexCharts
    },
    props: {
      values: Array,
      categories: Array
    },
    computed: {
      barChart() {
        return {
          series: this.values,
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
          }],
          plotOptions: {
            bar: {
              borderRadius: 0,
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (duration) {
              let minutes = Math.floor((duration / (1000 * 60)) % 60);
              let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

              hours = (hours < 10) ? "0" + hours : hours;
              minutes = (minutes < 10) ? "0" + minutes : minutes;

              return hours + "h " + minutes + "m";
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          xaxis: {
            type: 'text',
            categories: this.categories,
            crosshairs: {
              show: false,
              width: 1
            }
          },
          yaxis: {
            show: false
          },
          legend: {
            position: 'top',
            offsetY: 0
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            shared: true,
            y: {
              formatter: function (val) {
                let minutes = Math.floor((val / (1000 * 60)) % 60);
                let hours = Math.floor((val / (1000 * 60 * 60)));

                hours = (hours < 10) ? "0" + hours : hours;
                minutes = (minutes < 10) ? "0" + minutes : minutes;

                return (hours + "h " + minutes + "m");
              }
            },
            followCursor: true,
          },
        };
      },
    }
  }
</script>
