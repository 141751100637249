<template>
  <apexchart type="rangeBar" height="450" :options="rangeBarChart"
     :series="values">
  </apexchart>
</template>
<script>
  import VueApexCharts from "vue3-apexcharts";

  export default {
    name: 'RangeBarChart',
    components: {
      apexchart: VueApexCharts
    },
    props: {
      values: Array
    },
    computed: {
      rangeBarChart() {
        return {
          series: this.values,
          chart: {
            height: 450,
            type: 'rangeBar',
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '50%',
              rangeBarGroupRows: true
            },
            series: {
              dataSorting: {
                enabled: true
              },
            }
          },
          tooltip: {
            custom: function({ seriesIndex, dataPointIndex, w }) {
              let y_val = w.globals.initialSeries[seriesIndex].data[dataPointIndex].y;

              let diff = y_val[1] - y_val[0];
              const date = new Date(null);
              date.setSeconds( diff / 1000);
              const utc = date.toUTCString();
              let date_display = utc.substr(utc.indexOf(":") - 2, 8);

              return (
                  '<div class="p-2">' +
                  "<span>" +
                  "Duration: " +
                  ": " +
                  date_display +
                  "</span>" +
                  "</div>"
              );
            }
          },
          xaxis: {
            type: 'datetime'
          },
          stroke: {
            width: 1
          },
          fill: {
            type: 'solid',
            opacity: 0.6
          },
          legend: {
            position: 'top',
            offsetY: 0
          },
        };
      },
    }
  }
</script>
