<template>
  <apexchart type="donut" height="100" :options="donutChart"
     :series="values"
  ></apexchart>
</template>
<script>
  import VueApexCharts from "vue3-apexcharts";

  export default {
    name: 'DonutChart',
    components: {
      apexchart: VueApexCharts
    },
    props: {
      values: Array,
      colors: Array,
      labels: Array
    },
    computed: {
      donutChart() {
        return {
          series: this.values,
          chart: {
            type: 'donut',
          },
          colors: this.colors,
          labels: this.labels,
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
          tooltip: {
            y: {
              formatter: function (val) {
                let minutes = Math.floor((val / (1000 * 60)) % 60);
                let hours = Math.floor((val / (1000 * 60 * 60)));

                hours = (hours < 10) ? "0" + hours : hours;
                minutes = (minutes < 10) ? "0" + minutes : minutes;

                return (hours + "h " + minutes + "m");
              }
            }
          },
        }
      },
    }
  }
</script>
