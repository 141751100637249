import dayjs from "dayjs";
import {colors} from '../colors/colors.js';

/*
 * data - data from Fauna DB
 * from -
 * to -
 * tz - timezone
 * Exports data in a form of elements with properties
 * Like project reference and block color
 * Breaks down elements spanning across multiple days
 */
 // TODO: merge projects info in here
export function normalizeElements(data, from, to, tz, projects) {
  const elementsData = []; // Elements data items
  let overlapElements = [];

  // Go through individual reports -> report
  data.forEach((report) => {
    // Go through individual blocks within report -> block
    report.data.blocks.forEach((block) => {
      if (block.type === 'Block') {
        // Type Block timer
        // Include only non-empty elements
        if (block.elements.length) {
          // Go through elements within blocks
          block.elements.forEach((element) => {
            // Close the element if not closed
            if (typeof element.end === 'undefined') {
              element.end = report.ts / 1000;
            }

            // Enhance element data
            element.block = block.id;
            element.color = block.color;

            element.project = 'No project';
            element.projectColor = 'gray';
            if (block.projectRef !== "null") {
              let project = projects.find(item => item.id === block.projectRef);
              if (project) {
                element.project = project.name;
                element.projectColor = project.color;
              }
            }

            element.title = block.title;
            element.date = dayjs(element.start).format('YYYY-MM-DD');
            element.duration = element.end - element.start;

            // Days check
            // Break down the block if it overlaps
            overlapElements = overlap(element.start, element.end, tz);

            // There is an overlap - multiple days
            // Create multiple elements with different timestamps
            if (overlapElements.length) {
              for (let i = 0; i < overlapElements.length; i++) {
                element.start = overlapElements[i].start;
                element.end = overlapElements[i].end;
                element.date = dayjs(overlapElements[i]).format('YYYY-MM-DD');
                element.duration = element.end - element.start;
                elementsData.push(element);
              }
            } else {
              elementsData.push(element);
            }
          });
        }
      } else if (block.type === 'BlockCounter') {
        // Type BlockCounter
        // Data and Series
        // TODO
      }
    });
  });
  return {
    elementsBlock: elementsData
  };
}

/*
 * Calculates the overlap of an element and returns back the array in elements
 * start - start of the element
 * end - end of the element
 * tz - timezone
 */
// TODO: check timezones - check how we store data and when we need timezone conversion
function overlap(start, end, tz) {
  // end = end + (86400000 * 3);
  let el_start = dayjs(start).tz(tz).startOf('day');
  let el_end = dayjs(end).tz(tz).startOf('day');
  let diff = el_end.diff(el_start, 'day');

  const elements = [];

  // There is a difference of more than a day
  if (diff !== 0) {
    // Create elements array that spans across multiple days
    for (let i = 0; i < diff; i++) {
      if (i === 0) {
        // First item in the diff
        elements.push({
          start: start,
          end: dayjs(end).tz(tz).endOf('day').valueOf()
        });
      } else if (i !== 0 && i === diff - 1) {
        // Last item in the diff
        elements.push({
          start: dayjs(start).tz(tz).add(i, 'day').startOf('day').valueOf(),
          end: end
        });
      } else {
        // Items in-between
        elements.push({
          start: dayjs(start).tz(tz).add(i, 'day').startOf('day').valueOf(),
          end: dayjs(end).tz(tz).endOf('day').valueOf()
        });
      }
    }
  }
  return elements;
}

/*
 * Prepares a structure for series with dates, which dates are in the range
 * Output
    [
      "2021-08-11",
      "2021-08-12"
    ]
    * from - from when the report is
    * to - to when the report is
 */
export function reportsPrepareDates(from, to) {
  let cal_start = dayjs(from);
  let cal_end = dayjs(to);
  let cal_diff = cal_end.diff(cal_start, 'day');

  // Prepare base structure for
  const reportDateSeries = [];
  for (let i = 0; i < cal_diff + 1; i++) {
    let cal_this = cal_start.add(dayjs.duration({'days' : i})).format('YYYY-MM-DD');
    reportDateSeries.push(cal_this);
  }
  return reportDateSeries;
}

/*
 Returns formatted total time report
 */
export function reportsTotalTime(elements) {
  let total = 0;
  elements.forEach((element) => {
    total = total + element.duration;
  });

  let minutes = Math.floor((total / (1000 * 60)) % 60);
  let hours = Math.floor((total / (1000 * 60 * 60)));

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;

  return hours + "h " + minutes + "m";
}

/*
 * elements - normalised elements data
 * days - number of days to prepare empty values
 * groupBy - will be a day - date
 * group - the group we are targeting - project or color
 * Output
 * {categories: [], series: []}
 * [
  "2021-08-11",
  "2021-08-12"
  ]
  [{
    "name": "blue",
    "data": [
      5.67,
      8.47
    ],
    "color": "#3B82F6",
    "total": 163211735
  }]
 */

export function reportsBarChart(elements, reportsDateRange, group) {
  const data = [];
  const dataValues = [];

  //
  for (let i = 0; i < reportsDateRange.length; i++) {
    dataValues.push(0);
  }

  elements.forEach((element) => {
    // Find in data if the group has been added already - project/color
    let itemIndex = data.findIndex(item => item.name === element[group]);
    if (itemIndex === -1) {
      // Item not found, we will add it

      // Use project color instead of element colour
      let colorGroup = 'color';
      if (group === 'project') {
        colorGroup = 'projectColor';
      }

      let colorIndex = colors.findIndex(item => item.value === element[colorGroup]);
      data.push(
        {
          name: element[group],
          data: JSON.parse(JSON.stringify(dataValues)),
          color: '#' + colors[colorIndex].colors[400],
          total: 0
        }
      );
      itemIndex = data.length - 1;
    }

    // Add values to the data values
    let valueIndex = reportsDateRange.indexOf(element.date);
    data[itemIndex].data[valueIndex] = data[itemIndex].data[valueIndex] + element.duration;
  });

  return data;
}

export function reportsRangeBarChart(elements, group) {
  const data = [];

  elements.forEach((element) => {
    // Find in data if the group has been added already - project/color
    let itemIndex = data.findIndex(item => item.name === element[group]);
    if (itemIndex === -1) {
      // Item not found, we will add it

      // Use project color instead of element color
      let colorGroup = 'color';
      if (group === 'project') {
        colorGroup = 'projectColor';
      }

      let colorIndex = colors.findIndex(item => item.value === element[colorGroup]);
      data.push(
          {
            name: element[group],
            data: [],
            color: '#' + colors[colorIndex].colors[400],
            total: 0
          }
      );
      itemIndex = data.length - 1;
    }

    const timeReduce = dayjs(element.date).valueOf();
    // Add values to the data values
    data[itemIndex].data.push(
      {
        x: element.date,
        y: [
          element.start - timeReduce,
          element.end - timeReduce
        ]
      }
    );
  });

  return data;
}

export function reportsDonutChart(elements, group) {
  const dataLabels = [];
  const dataColors = [];
  const dataValues = [];

  elements.forEach((element) => {
    // Find in data if the group has been added already - project/color
    let itemIndex = dataLabels.indexOf(element[group]);
    if (itemIndex === -1) {
      // Item not found, we will add it

      // Use project color instead of element colour
      let colorGroup = 'color';
      if (group === 'project') {
        colorGroup = 'projectColor';
      }

      let colorIndex = colors.findIndex(item => item.value === element[colorGroup]);
      dataLabels.push(element[group]);
      dataColors.push('#' + colors[colorIndex].colors[400]);
      dataValues.push(0);
      itemIndex = dataLabels.length - 1;
    }

    // Add values to the data values
    dataValues[itemIndex] = dataValues[itemIndex] + element.duration;
  });

  return {
    labels: dataLabels,
    colors: dataColors,
    values: dataValues
  };
}

export function habits() {

}
