<template>
  <div class="max-w-7xl px-4 mx-auto sm:px-6 lg:px-8 text-center pt-2" style="width: 80rem;">
    <div class="inline-flex text-base leading-6 font-medium rounded-md text-white text-gray-600 cursor-not-allowed">
      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      Loading fun assets...
    </div>
  </div>
</template>

<script>
  export default {
    name: "Loading",
  };
</script>
